<script>
    import Layout from "../../layouts/main";
    import Master from "@/apis/Master";
    import Vue from "vue";
    import Swal from "sweetalert2";
    import PageHeader from "@/components/page-header";
    import {required} from "vuelidate/lib/validators";

    /**
     * Orders Component
     */
    export default {
        components: {
            Layout,
            PageHeader
        },
        data() {
            return {
                title: "USER PAYMENTS",
                items: [
                    {
                        text: "DASHBOARD",
                        href: "/"
                    },
                    {
                        text: "USER PAYMENTS",
                        active: true
                    }
                ],
                ordersData: [],
                deliveryData: [],
                paymentData: [],
                totalRows: 1,
                paymentStatus: 3,
                currentPage: 1,
                perPage: 50,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                paymentModeData: [],
                showmodal: false,
                showmodalPayment: false,
                submitted: false,
                submittedPayment: false,
                sortBy: "id",
                sortDesc: true,
                showRejectModel: false,
                submittedReject: false,
                paid: 0,
                status: [],
                reject:{
                    paymentId : "",
                    rejectedReason: ""
                },
                fields: [
                    { key: "orderNum", sortable: true, label: "ORDER ID" },
                    { key: "paidAmount", sortable: true, label: "AMOUNT" },
                    { key: "paymentMode", sortable: true, label: "PAYMENT MODE" },
                    { key: "paidOn", sortable: true, label: "DATE" },
                    { key: "paymentStatus", sortable: true, label: "STATUS" },
                    { key: "action", sortable: false, label: "ACTION" },
                ]
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.paymentData.length;
            }
        },
        created() {
            //
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.paymentData.length;

            this.userPaymentFilter();

            Master.paymentMode({
                trash: 0
            }).then((res) => {
                console.log(res)
                this.paymentModeData = res.data.data;
            })


        },
        validations: {
            reject:{
                rejectedReason: {required}
            }
        },
        methods: {
            /**
             * Search the table data with search input
             */
            userPaymentFilter(){
                Master.userPayment({
                    userId: 1,
                    paymentStatus:this.paymentStatus
                }).then((res) => {
                    console.log(res)
                    this.paymentData = res.data.data;
                });
            },

            orderDetails(data){
                localStorage.setItem('orderId',data)
            },

            deliveryRecipt(event){
                this.delivery.file = event.target.files[0]
            },

            paymentReceipt(){
                this.payment.file = event.target.files[0]
            },


            handleRejectedSubmit(){
                this.submittedReject = true;
                this.$v.$touch();
                if (this.$v.reject.$invalid) {
                    return;
                } else {
                    this.paymentReject()
                }
            },



            deletePayment(id){
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes, delete it!"
                }).then(result => {
                    if (result.value) {
                        // var user = JSON.parse(localStorage.getItem('user'))
                        Master.paymentDelete(
                            {
                                id: id,
                                userId:1,
                            }).then(res => {
                            console.log(res)
                            Swal.fire("Deleted!", "Your data has been deleted.", "success");
                            // this.successMessage = res.data.message;
                            this.userPaymentFilter()
                        })
                    }
                });
            },
            rejectModal(data){
                this.showRejectModel = true;
                this.reject.paymentId = data
            },
            paymentConfirm(id){
                Master.orderPaymentConfirm(
                    {
                        id: id,
                        userId:1,
                        rejectedReason: "",
                        paymentStatus:1
                    }).then(res => {
                    console.log(res)
                    Vue.swal({
                        position: "center",
                        icon: "success",
                        title: ""+res.data.message+"",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    // this.successMessage = res.data.message;
                    this.userPaymentFilter()


                })


            },
            paymentReject(){
                Master.orderPaymentConfirm(
                    {
                        id: this.reject.paymentId,
                        userId:1,
                        rejectedReason: this.reject.rejectedReason,
                        paymentStatus:2
                    }).then(res => {
                    console.log(res)
                    this.userPaymentFilter()
                    Vue.swal({
                        position: "center",
                        icon: "success",
                        title: ""+res.data.message+"",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    // this.successMessage = res.data.message;
                    this.showRejectModel = false
                    this.submittedReject = false

                })


            },

            hideRejectModel(){
                this.showRejectModel = false
                this.submittedReject = false
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            }
        }
    };
</script>

<template>
    <Layout style="text-transform: uppercase !important;">
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body" style="text-transform: uppercase !important;">
                        <b-tabs nav-class="nav-tabs-custom">
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-4">
                                    <div id="tickets-table_length" class="dataTables_length">
                                        <label class="d-inline-flex align-items-center">
                                            Show&nbsp;
                                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-8 user_search_blk">
                                    <div class="prodyc_select2 prod_index text-md-right">
                                        <select v-on:change='userPaymentFilter' v-model="paymentStatus">
                                            <option :value="3">PRODUCT STATUS</option>
                                            <option :value="0">PENDING CONFIRMATION</option>
                                            <option :value="1">CONFIRMED</option>
                                            <option :value="2">REJECTED</option>
                                        </select>
                                    </div>
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                           
                                            <b-form-input
                                                    v-model="filter"
                                                    placeholder="Search"
                                                    type="search"
                                                    class="form-control form-control-sm ml-2"
                                            ></b-form-input>
                                        </label>
                                    </div>
                                    
                                </div>
                                <!-- End search -->
                            </div>
                            <div class="table-responsive">
                                <b-table
                                        class="table-centered"
                                        :items="paymentData"
                                        :fields="fields"
                                        responsive="sm"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :filter="filter"
                                        :filter-included-fields="filterOn"
                                        @filtered="onFiltered"
                                        show-empty
                                        striped
                                        sort-icon-left
                                >




                                    <template v-slot:cell(orderNum)="row">
                                        <router-link to="/open-order/order-details" :data-date="row.item.orderId"><p class="badge font-size-12"  @click="orderDetails(row.item.orderId)">
                                            {{row.item.orderNum}}
                                        </p></router-link>
                                    </template>
                                    <template v-slot:cell(paidAmount)="row">
                                        <p class="badge font-size-12">
                                            QAR {{row.item.paidAmount.toLocaleString()}}
                                        </p>
                                    </template>

                                    <template v-slot:cell(paymentMode)="row">
                                        <p class="badge font-size-12">
                                            {{row.item.paymentMode}}
                                        </p>
                                    </template>
                                    <template v-slot:cell(paidOn)="row">
                                        <p class="badge font-size-12">
                                            {{row.item.paidOn}}
                                        </p>
                                    </template>



                                    <template v-slot:cell(paymentStatus)="row">
                                        <p v-if="row.value == 0"
                                           class="badge font-size-12 badge-soft-warning"
                                        >PENDING CONFIRMATION</p>
                                        <p v-if="row.value == 1"
                                          class="badge font-size-12 badge-soft-success"
                                         >CONFIRMED</p>
                                        <div v-if="row.value == 2"

                                        >
                                            <p class="badge font-size-12 badge-soft-danger">REJECTED</p>

                                            <ul class="pl-1" style="list-style-type: none;">
                                                <li>
                                                    <span class="badge prdt-onr-name"  style="white-space: pre-wrap; font-size: 70%">Reason : {{row.item.rejectedReason}}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </template>

                                    <template v-slot:cell(action) = "data">
                                        <a
                                                target="_blank"
                                                :href="data.item.paymentReceipt"
                                                class="mr-2 text-dark"
                                                v-b-tooltip.hover
                                                title="VIEW RECEIPT"
                                        ><i class="mdi mdi-file-check font-size-14"></i></a>


                                        <a v-if="data.item.paymentStatus == 0"
                                           href="javascript:void(0);"
                                           class="mr-2 text-success"
                                           @click="paymentConfirm(data.item.id)"
                                           v-b-tooltip.hover
                                           title="CONFIRM PAYMENT"
                                        >
                                            <i class="mdi mdi-check font-size-14"></i>
                                        </a>
                                        <a v-if="data.item.paymentStatus == 0"
                                           href="javascript:void(0);"
                                           class="mr-2 text-danger"
                                           @click="rejectModal(data.item.id)"
                                           v-b-tooltip.hover
                                           title="REJECT PAYMENT"
                                        >
                                            <i class="mdi mdi-cancel font-size-14"></i>
                                        </a>
                                        <a
                                                href="javascript:void(0);"
                                                class="mr-2 text-danger"
                                                @click="deletePayment(data.item.id)"
                                                v-b-tooltip.hover
                                                title="DELETE PAYMENT"
                                        >
                                            <i class="mdi mdi-trash-can font-size-14"></i>
                                        </a>


                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="dataTables_paginate paging_simple_numbers float-right">
                                        <ul class="pagination pagination-rounded mb-0">
                                            <!-- pagination -->
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!--              </b-tab>-->
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
                id="modal-1"
                v-model="showRejectModel"
                title="ADD REJECTED REASON"
                title-class="text-dark font-18"
                hide-footer
                @close="hideRejectModel"
        >

            <form @submit.prevent="handleRejectedSubmit">
                <div class="form-group">
                    <label for="name">REASON *</label>
                    <textarea class="form-control" placeholder="ENTER REJECT REASON" v-model="reject.rejectedReason" :class="{ 'is-invalid': submittedReject   && $v.reject.rejectedReason.$error  }"></textarea>

                    <div
                            v-if="submittedReject && !$v.reject.rejectedReason.required"
                            class="invalid-feedback"
                    >REASON IS REQUIRED</div>
                </div>

                <div class="text-right">
                    <button type="submit" class="btn btn-success">SAVE</button>
                    <b-button class="ml-1" variant="danger" @click="hideRejectModel">CANCEL</b-button>
                </div>
            </form>
        </b-modal>
        <!-- end modal -->
    </Layout>
</template>